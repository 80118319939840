var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('q-item',{staticClass:"q-pa-none column"},[_c('BaseInputSelect',{staticClass:"filters-show",attrs:{"value":_vm.item,"options":_vm.items},on:{"input":_vm.input},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var itemProps = ref.itemProps;
var opt = ref.opt;
var selected = ref.selected;
var toggleOption = ref.toggleOption;
var itemEvents = ref.itemEvents;
return [_c('q-item',_vm._g(_vm._b({staticClass:"flex items-center justify-start redesign",class:{
          'filters-show__title': opt.key === 0,
          'filters-show__text': opt.key !== 0,
        },attrs:{"clickable":!!opt.key}},'q-item',itemProps,false),itemEvents),[_vm._v(" "+_vm._s(opt.title)+" ")])]}},{key:"selected-item",fn:function(ref){
        var tabindex = ref.tabindex;
        var index = ref.index;
        var opt = ref.opt;
return [_c('q-item',{staticClass:"flex items-center justify-center redesign q-pa-none",attrs:{"dense":""}},[_vm._v(" "+_vm._s(opt.title)+" ")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }