


































import { Vue, Component } from 'vue-property-decorator'
import BaseInputSelect from '@/components/UI/BaseInputSelect.vue'
import { meetingsStore } from '@/store'

interface Item {
  key: number
  title: string
}

@Component({
  components: {
    BaseInputSelect,
  },
})
export default class MeetingsRightBarFilters extends Vue {
  get item (): Item {
    return {
      key: meetingsStore.state.isAllMeetings ? 2 : 1,
      title: meetingsStore.state.isAllMeetings
        ? this.$t('meetings.rightBar.filters.show.all').toString()
        : this.$t('meetings.rightBar.filters.show.next').toString(),
    }
  }

  get items (): Item[] {
    return [{
      key: 0,
      title: this.$t('meetings.rightBar.filters.show.title').toString(),
    }, {
      key: 1,
      title: this.$t('meetings.rightBar.filters.show.next').toString(),
    }, {
      key: 2,
      title: this.$t('meetings.rightBar.filters.show.all').toString(),
    }]
  }

  private input (val: Item): void {
    const isAllMeetings = val.key === 2
    if (!val.key) return
    if (meetingsStore.state.isAllMeetings !== isAllMeetings) {
      window.goal('Calendar', {
        Calendar: `Фильтр встреч — «${
          isAllMeetings ? 'Все' : 'Предстоящие и текущие'
        }»`,
      })
    }
    meetingsStore.actions.setIsAllMeetings(isAllMeetings)
  }
}
